
import { wait } from "@testing-library/react";
import classnames from "classnames";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { async } from "rxjs";
import { authenticationService } from "../../Services/authenticationService";
const Login = ({ navigation }) => {
    const [state, setState] = React.useState({});
    const [mail, setmail] = useState();
    const [password, setpassword] = useState()
    const history = useHistory();
     useEffect(() => {
        let urlElements =window.location.href.split('?');        
        if(urlElements[urlElements.length-1]=="session=false"){
            alert('Session timed out, Login to continue');
            history.push('/auth/login');
        }
    }, []);
   
    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
       
    });

    const Login = async () => {
        if (mail == null || password == null) {
            alert("Enter mail or password");
            return;
        }
        const result = await authenticationService.login(mail, password);

        if (!result) {
            alert("Mail or password incorrect!");
            return;
        } else {
            setTimeout(function () {
                if (authenticationService.currentUserValue != null) {
                    history.push('/user/charges');
                    alert("Welcome to SuperBill Charge Capture");
                }
            }, 3000);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            Login();
        }
    }

    return (
        <>
            <div className="content" >
                <Container>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        <Card className="card-lock card-white text-center">
                            <CardHeader>
                                <img alt="..." src={require("../../assets/img/SuperBill_Icon.jpg").default} />
                            </CardHeader>
                            <CardBody>
                                <InputGroup className={classnames({ "input-group-focus": state.emailFocus, })}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="tim-icons icon-email-85" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Email" type="text" onChange={(newValue) => setmail(newValue.target.value)}
                                        onFocus={(e) => setState({ ...state, emailFocus: true })}
                                        onBlur={(e) => setState({ ...state, emailFocus: false })} onKeyDown={handleKeyDown} />
                                </InputGroup>
                                <InputGroup className={classnames({ "input-group-focus": state.passFocus, })}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="tim-icons icon-lock-circle" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Password" type="password" onChange={(newValue) => setpassword(newValue.target.value)}
                                        onFocus={(e) => setState({ ...state, passFocus: true })}
                                        onBlur={(e) => setState({ ...state, passFocus: false })} onKeyDown={handleKeyDown} />
                                </InputGroup>
                                <div className="pull-left">
                                    <h6>
                                        <a className="link footer-link" href="#pablo" onClick={(e) => e.preventDefault()}> Create Account</a>
                                    </h6>
                                </div>
                                <div className="pull-right">
                                    <h6>
                                        <a className="link footer-link" href="#pablo" onClick={(e) => e.preventDefault()}>Forgot Password</a>
                                    </h6>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button block className="mb-3" color="middle" onClick={Login} size="lg">Login</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Container>
            </div>
        </>
    );
};

export default Login;
