
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Table
} from "reactstrap";
import { async } from 'rxjs';
import { authenticationService } from '../../Services/authenticationService';
import { DefaultColumnFilter, Filter } from '../Charges/Filter';
import Userstable from './Userstable';
const Users = (props) => {
    const history = useHistory();

    if (authenticationService.currentUserValue == null) {
        history.push('/auth/login');
    }
    const [prefixOptions, setprefixOptions] = useState([]);
    const [roleOptions, setroleOptions] = useState([]);

    const [user_data, setuser_data] = useState();

    useEffect(async () => {
        setuser_data(authenticationService.userdataValue);
        await getallmappings();
        getallocations();
        const prefixoptions = [
            { value: 1, label: "Mr" },
            { value: 2, label: "Mrs" },
            { value: 3, label: "Ms" },
            { value: 4, label: "Dr" }
        ];
        setprefixOptions(prefixoptions);
        const roles = [
            { value: 1, label: "Admin" },
            { value: 2, label: "User" },
            { value: 3, label: "Data entry" },
        ];
        setroleOptions(roles);


    }, []);
    const [allmappings, setallmapings] = useState([]);
    const [data, setData] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: 'User ID',
                accessor: 'user_id',
            },
            {
                Header: "Prefix",
                accessor: 'suffix'
            },
            {
                Header: 'First Name',
                accessor: 'first_name',
            },
            {
                Header: 'Last Name',
                accessor: 'last_name',
            },
            {
                Header: 'Email',
                accessor: 'mail_id'
            },
            {
                Header: 'DOB',
                accessor: 'dob'
            },
            {
                Header: 'Cell Phone Number ',
                accessor: 'phone_number'
            },
            {
                Header: 'Address',
                accessor: 'mailling_address'
            },
            {
                Header: 'NPI Number',
                accessor: 'npi_number'
            }
            ,
            {
                Header: 'Role',
                accessor: 'role'
            },
            {
                Header: 'User Status',
                accessor: 'user_status'
            }
        ]

    );

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };
    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 150,
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 220,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
    }));

    const classes = useStyles();
    const [selectedImage, setSelectedImage] = useState(null);
    const [prefix, setprefix] = useState(1);
    const [selectedsuffix, setSelectedsuffic] = useState("");
    const prefixhandle = event => {
        setSelectedsuffic(event.value);
        setprefix(prefixOptions[event.value - 1]);
    }
    const [role, setrole] = useState(1);
    const rolehandle = event => {
        setrole(roleOptions[event.value - 1]);
    }
    const [first_name, setfirstname] = useState("");
    const firstnamehandle = event => {
        setfirstname(event.target.value);
    }
    const [last_name, setlastname] = useState("");
    const lastnamehandle = event => {
        setlastname(event.target.value);
    }
    const [mail, setmail] = useState("");
    const mailnamehandle = event => {
        setmail(event.target.value);
    }
    const [dob, setdob] = useState("");
    const dobhandle = event => {
        setdob(event.target.value);
    }

    const [npi, setnpi] = useState("");
    const npihandle = event => {
        setnpi(event.target.value);
    }
    const [address, setaddress] = useState("");
    const addresshandle = event => {
        setaddress(event.target.value);
    }
    const [mobilenumber, setmobilenumber] = useState("");
    const mobilenumberhandle = event => {
        setmobilenumber(event.target.value);
    }
    const [userstatus, setuserstatus] = useState();
    const [groups, setgroups] = useState([]);

    const [editselectedImage, editsetSelectedImage] = React.useState(null);
    const [editprefix, editsetprefix] = useState(1);
    const [editselectedsuffix, editsetSelectedsuffix] = useState("");
    const editprefixhandle = event => {
        editsetSelectedsuffix(event.value);
        editsetprefix(prefixOptions[event.value - 1]);
    }
    const [editrole, editsetrole] = useState(1);
    const editrolehandle = event => {
        editsetrole(roleOptions[event.value - 1]);
    }
    const [editfirst_name, editsetfirstname] = useState("");
    const editfirstnamehandle = event => {
        editsetfirstname(event.target.value);
    }
    const [editlast_name, editsetlastname] = useState("");
    const editlastnamehandle = event => {
        editsetlastname(event.target.value);
    }
    const [editmail, editsetmail] = useState("");
    const editmailnamehandle = event => {
        editsetmail(event.target.value);
    }
    const [editdob, editsetdob] = useState("");
    const editdobhandle = event => {
        editsetdob(event.target.value);
    }

    const [editnpi, editsetnpi] = useState("");
    const editnpihandle = event => {
        editsetnpi(event.target.value);
    }
    const [editaddress, editsetaddress] = useState("");
    const editaddresshandle = event => {
        editsetaddress(event.target.value);
    }
    const [editmobilenumber, editsetmobilenumber] = useState("");
    const editmobilenumberhandle = event => {
        editsetmobilenumber(event.target.value);
    }

    const [selectedgroup, setSelectedgroup] = useState();
    const groupchangehandle = event => {
        groupchange(event.value, groups);
    }

    function groupchange(group_id, groups) {
        try {
            handleSelect();
            setData([]);
            getallocations();
            var array2 = {
                user: []
            };
            seteditlocations([]);
            editsetSelectedlocation([]);
            setSelectedlocation(0);
            groups.forEach(element => {
                if (element.value == group_id) {
                    setSelectedgroup(element);
                }
            });

            setactivegroup(group_id);
            let index = group_id;
            var array = {
                options: []
            };

            for (var val of Alllocations) {
                if (val.group_id == group_id) {
                    array.options.push(val);
                }
            }
            var locations = [];
            const options = array.options.map(d =>
            ({
                "value": d.location_id,
                "label": d.Name
            }));
            setlocations(options);
            seteditlocations(options);


            for (var val of allusers) {
                for (var i = 0; i < allmappings.length; i++) {
                    if (val.user_id == allmappings[i].user_id) {
                        var allgroups = allmappings[i].group_mapping;
                        for (var mapping of allgroups) {
                            if (mapping.group_id == group_id) {
                                array2.user.push(val);
                            }
                        }
                    }
                }
            }
        }
        catch (err) {

        }
        setData(array2.user);
    }

    const [selectedlocation, setSelectedlocation] = useState([]);
    const locationchangehandle = event => {
        var options = event;
        var value = [];
        for (var i = 0; i < options.length; i++) {
            var found = false;
            for (var temp of value) {
                if (temp.value == options[i].value) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                value.push(options[i]);
            }

        }

        setSelectedlocation(value);
    }
    const [editselectedlocation, editsetSelectedlocation] = useState([]);
    const editlocationchange = event => {
        var options = event;
        var value = [];
        for (var i = 0; i < options.length; i++) {
            var found = false;
            for (var temp of value) {
                if (temp.value == options[i].value) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                value.push(options[i]);
            }
        }
        editsetSelectedlocation(value);
    }
    const [activegroup, setactivegroup] = useState();
    const getallgroups = async (allmappings) => {
        try {
            const user_id = authenticationService.useridvalue;
            const role = authenticationService.userroleValue;
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getgroups/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            var array = [];
            const body = await response.json();
            if (!body.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
            const groups = body.data;
            for (var i = 0; i < groups.length; i++) {
                if (groups[i].CREATED_BY == user_id) {
                    array.push(groups[i]);
                }
            };

            if (role == 1) {
                for (var i = 0; i < allmappings.length; i++) {
                    if (user_id == allmappings[i].user_id) {
                        var allgroups = allmappings[i].group_mapping;
                        for (var groupmap of allgroups) {
                            for (var mapping of groups) {
                                if (mapping.group_id == groupmap.group_id) {
                                    array.push(mapping);
                                }
                            }
                        }
                    }
                }
            }

            let array2 = [...new Set(array)];

            const options = array2.map(d =>
            ({
                "value": d.group_id,
                "label": d.name
            })
            )

            setgroups(options);
            if (activegroup != null) {
                getusers(allmappings, activegroup);
                groupchange(activegroup, options);
            } else
                if (options.length > 0) {
                    getusers(allmappings, options[0].value);
                    setSelectedgroup(options[0]);
                    setactivegroup(options[0].value);
                    groupchange(options[0].value, options);

                } else {
                    getusers(allmappings);
                }
            handleSelect();


        } catch (err) {

        }
    };
    const [allusers, setallusers] = useState([]);
    const getusers = async (allmappings, activegroup) => {
        try {
            setData([]);
            let token = localStorage.getItem('token');

            const response = await fetch(process.env.REACT_APP_API_HOST + '/users/getusers/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const users_data = await response.json();
            if (!users_data.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
            const users = users_data.data;

            for (const val of users) {
                val.suffix = prefixstatustostring(val.suffix);
                val.user_status = statustostring(val.user_status);
                val.role = roletostring(val.role);
            };

            try {
                var array = [];
                for (const val of users) {
                    for (var i = 0; i < allmappings.length; i++) {
                        if (val.user_id == allmappings[i].user_id) {
                            var allgroups = allmappings[i].group_mapping;
                            for (var mapping of allgroups) {
                                if (mapping.group_id == activegroup) {
                                    array.push(val);
                                    break;
                                }
                            }
                        }
                    }
                }
            } catch (err) {
            }

            setallusers(users);
            setData(array);
        } catch (err) {

        }
    }

    const getallmappings = async () => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/users/getgroupsmapping/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => {
                response.json().then(json => {
                    if (!json.success) {
                        authenticationService.logout(true);
                        history.push('/auth/login?session=false');
                        return;
                    }
                    if (json.data != undefined) {
                        setallmapings(json.data.docs);
                        getallgroups(json.data.docs);
                        getusers(json.data.docs);
                    }
                    else {
                        setallmapings(json.data);
                        getallgroups(json.data);
                        getusers(json.data);
                    }

                })
            });

        } catch (err) {

        }
    }
    const [user_maping, setuser_maping] = useState();
    const getusermappings = async (user_id) => {
        try {
            for (var i = 0; i < allmappings.length; i++) {
                const val = allmappings[i];
                if (val.user_id == user_id) {
                    setuser_maping(val);
                    getuser_mapinglocations(val);
                }
            }
        } catch (err) {

        }
    };

    const [editlocations, seteditlocations] = useState([]);
    function getuser_mapinglocations(user_mapingData) {

        const location_ids = [];
        if (user_mapingData != null) {
            for (var i = 0; i < user_mapingData.group_mapping.length; i++) {
                if (user_mapingData.group_mapping[i].group_id == activegroup) {
                    for (var k = 0; k < user_mapingData.group_mapping[i].locations.length; k++) {
                        if (user_mapingData.group_mapping[i].locations[k].status == 1) {
                            location_ids.push(user_mapingData.group_mapping[i].locations[k].location_id);
                        }
                    }
                }
            }
        }
        var array = {
            options: []
        };

        for (var i = 0; i < Alllocations.length; i++) {
            for (var j = 0; j < location_ids.length; j++) {
                if (location_ids[j] == Alllocations[i].location_id) {
                    array.options.push(Alllocations[i]);
                }
            }
        }

        const options = array.options.map(d =>
        ({
            "value": d.location_id,
            "label": d.Name
        }));

        if (options.length > 0) {
            editsetSelectedlocation(options);
        } else {
            editsetSelectedlocation([]);
        }
    }

    const [locations, setlocations] = useState([]);
    const [Alllocations, setalllocations] = useState([]);
    const getallocations = async () => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getlocations/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (!result.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }

            await setalllocations(result.data.locations);

        } catch (err) {

        }
    };

    function stringtorole(role) {
        if (role == "Admin") {
            return roleOptions[0];
        } else if (role == "User") {
            return roleOptions[1];
        }
        else if (role == "Data entry") {
            return roleOptions[2];
        }
        return "";
    }

    function roletostring(role) {
        if (role == 1) {
            return "Admin";
        } else if (role == 2) {
            return "User";
        }
        else if (role == 3) {
            return "Data entry";
        }
        return "";
    }

    function statustostring(status) {
        if (status == 1) {
            return "Active";
        } else if (status == 2) {
            return "Deactivated";
        }
        else if (status == 3) {
            return "Deleted";
        }
        return "";
    }

    function statusfromstring(status) {
        if (status == "Active") {
            return 1;
        } else if (status == "Deactivated") {
            return 2;
        }
        else if (status == "Deleted") {
            return 3;
        }
        return 0;
    }

    function prefixstatustostring(status) {
        if (status == 1) {
            return "Mr";
        } else if (status == 2) {
            return "Mrs";
        }
        else if (status == 3) {
            return "Ms";
        }
        else if (status == 4) {
            return "Dr";
        }
        return "";
    }

    function prefixstatusfromstring(status) {
        if (status == "Mr") {
            return prefixOptions[0];
        } else if (status == "Mrs") {
            return prefixOptions[1];
        }
        else if (status == "Ms") {
            return prefixOptions[2];
        }
        else if (status == "Dr") {
            return prefixOptions[3];
        }
        return "";
    }

    async function updateuserstatus(status) {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/users/chnageuserstate/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    mail_id: editmail, user_status: status
                })
            }).then(response => response.json())
                .then(data => alert(data.message),
                    getallmappings(),
                    getallocations(),
                    getusers(allmappings));

            if (!response.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }

        } catch (err) {

        }
    }

    const submituser = async () => {
        try {
            let token = localStorage.getItem('token');
            if (first_name.trim() == "") {
                alert("Fill First Name");
                return;
            }
            if (last_name.trim() == "") {
                alert("Fill Last Name");
                return;
            }
            if (role.value == "" || role.value == undefined) {
                alert("Select Role");
                return;
            }
            if (mail.trim() == "") {
                alert("Fill Email");
                return;
            }
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (regex.test(mail) === false) {
                alert("Fill valid Email");
                return;
            }
            if (mobilenumber.trim() == "") {
                alert("Fill Contact Mobile Number");
                return;
            }

            if (activegroup == undefined) {
                alert("Select Practice Group");
                return;
            }
            if (selectedlocation == undefined) {
                alert("Select Location");
                return;
            }
            var locations = [];
            for (var location of selectedlocation) {
                locations.push(location.value);
            }
            const formData = new FormData();
            formData.append('file', selectedImage);
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('suffix', selectedsuffix);
            formData.append('mail_id', mail);
            formData.append('dob', dob);
            formData.append('phone_number', mobilenumber);
            formData.append('mailling_address', address);
            formData.append('npi_number', npi);
            formData.append('user_status', 1);
            formData.append('role', role.value);
            formData.append('group_id', activegroup);
            formData.append('location_id', locations);
           
            let response = axios.post(process.env.REACT_APP_API_HOST + '/users/adduser', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {                
                if (!res.data.success) {
                    authenticationService.logout(true);
                    history.push('/auth/login?session=false');
                    return;
                }
                alert(res.data.message);
                getallmappings();
                getallocations();
            }).catch(err => console.log());

        } catch (exception) {
            console.log(exception);
            alert(exception);
        }
        reset();
    }

    const updateuser = async () => {
        try {

            if (editfirst_name.trim() == "") {
                alert("Fill First Name");
                return;
            }
            if (editlast_name.trim() == "") {
                alert("Fill Last Name");
                return;
            }
            if (editrole == "") {
                alert("Select Role");
                return;
            }

            if (editmobilenumber.trim() == "") {
                alert("Fill Contact Mobile Number");
                return;
            }

            if (activegroup == undefined) {
                alert("Select Practice Group");
                return;
            }
            if (editselectedlocation == undefined) {
                alert("Select Location");
                return;
            }
            var locations = [];
            for (var location of editselectedlocation) {
                locations.push(location.value);
            }
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('file', editselectedImage);
            formData.append('first_name', editfirst_name);
            formData.append('last_name', editlast_name);
            formData.append('suffix', "" + editselectedsuffix);
            formData.append('mail_id', editmail);
            formData.append('dob', editdob);
            formData.append('phone_number', editmobilenumber);
            formData.append('mailling_address', editaddress);
            formData.append('npi_number', editnpi);
            formData.append('user_status', 1);
            formData.append('role', editrole.value);
            formData.append('group_id', activegroup);
            formData.append('modified_by', user_data.docs.user_id);
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            };
            axios.post(process.env.REACT_APP_API_HOST + '/users/updateuser', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            },).then(res => {               
                if (!res.data.success) {
                    authenticationService.logout(true);
                    history.push('/auth/login?session=false');
                    return;
                }
                alert(res.data.message);
                getallmappings();
                getallocations();
            }).catch(err => console.log())
        } catch (exception) {
            console.log(exception);
        }
        reset();
    }


    const reset = () => {
        setprefix(0);
        setrole(1);
        setfirstname("");
        setlastname("");
        setmail("");
        setdob("");
        setnpi("");
        setaddress("");
        setmobilenumber("");
        setSelectedImage("");
        setSelectedlocation(1);

        editsetprefix(0);
        editsetrole(0);
        editsetfirstname("");
        editsetlastname("");
        editsetmail("");
        editsetdob("");
        editsetnpi("");
        editsetaddress("");
        editsetmobilenumber("");
        editsetSelectedImage("");
        editsetSelectedlocation("");
    }
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange2 = event => {
        const fileUploaded = event.target.files[0];
        setSelectedImage(fileUploaded);
    };

    const handleChangeedit = event => {
        const fileUploaded = event.target.files[0];
        editsetSelectedImage(URL.createObjectURL(fileUploaded));
    }
    const [selfselected, setselfselected] = useState(false);
    function handleuserselected(evt) {
        const user_id = authenticationService.useridvalue;
        data.map((user) => {
            if (user.user_id == evt.value) {
                editsetprefix(prefixstatusfromstring(user.suffix));
                editsetrole(stringtorole(user.role));
                editsetfirstname(user.first_name);
                editsetlastname(user.last_name);
                editsetmail(user.mail_id);
                editsetdob(user.dob);
                editsetnpi(user.npi_number);
                editsetmobilenumber(user.phone_number);
                editsetaddress(user.mailling_address);
                editsetSelectedImage(user.user_image);
                setuserstatus(statusfromstring(user.user_status));
                getusermappings(user.user_id);
                if (user.user_id == user_id) {
                    setselfselected(true);
                } else {
                    setselfselected(false);
                }
            }
        })
    }

    function handleSelect() {
        editsetprefix(1);
        editsetrole(1)
        editsetfirstname("");
        editsetlastname("");
        editsetmail("");
        editsetdob("");
        editsetnpi("");
        editsetmobilenumber("");
        editsetaddress("");
        editsetSelectedImage("");
        setuserstatus("");
        editsetSelectedlocation([]);
    }

    return (
        <div className="content">
            <Row>
                <Label md="2">Select Practice Group</Label>
                <Col md="4">
                    <FormGroup>
                        <Select options={groups} onChange={groupchangehandle} defaultValue={selectedgroup} value={selectedgroup} />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Tabs onSelect={index => handleSelect()}>
                                <TabList >
                                    <Tab eventKey={1}>Users</Tab>
                                    <Tab eventKey={2}>New User</Tab>
                                </TabList>
                                <TabPanel>
                                    <Row>
                                        <Col md="12">
                                            <Userstable fromChildToParentCallback={handleuserselected} columns={columns} data={data}></Userstable>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" >
                                            <Tabs>
                                                <TabList>
                                                    <Tab>User Info</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <Row>
                                                        <Col md="8">
                                                            <Card>
                                                                <CardBody>
                                                                    <Form className="form-horizontal">
                                                                        <Row>
                                                                            <Label md="2">Prefix</Label>
                                                                            <Col md="4" >
                                                                                <Select options={prefixOptions} onChange={editprefixhandle} defaultValue={editprefix} value={editprefix} />
                                                                            </Col>
                                                                            <Label md="2">Role</Label>
                                                                            <Col md="4" >
                                                                                <FormGroup>
                                                                                    <Select options={roleOptions} onChange={editrolehandle} defaultValue={editrole} value={editrole} />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Label md="2">First Name</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="text" name='first_name' onChange={editfirstnamehandle} value={editfirst_name} autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Label md="2">Last Name</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="text" onChange={editlastnamehandle} value={editlast_name} autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Label md="2">Email</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="email" disabled onChange={editmailnamehandle} value={editmail} autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Label md="2">DOB</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="date" onChange={editdobhandle} value={editdob} autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Label md="2">NPI Number</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="text" onChange={editnpihandle} value={editnpi} autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Label md="2">Cell Phone Number</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="number" onChange={editmobilenumberhandle} value={editmobilenumber} autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Label md="2">Select Location</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Select options={editlocations} isMulti onChange={editlocationchange} defaultValue={editselectedlocation} value={editselectedlocation} />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Label md="2">Address</Label>
                                                                            <Col md="4">
                                                                                <FormGroup>
                                                                                    <Input type="textarea" onChange={editaddresshandle} value={editaddress} multiline autoComplete="off" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form>
                                                                </CardBody>
                                                                <CardFooter>
                                                                    <Form className="form-horizontal">
                                                                        <Row>
                                                                            <Col md="6">
                                                                            </Col>
                                                                            <Col md="6">

                                                                                {!selfselected && <>
                                                                                    <Button className="btn-fill" color="primary" onClick={updateuser}>Update</Button>
                                                                                    {userstatus == 1 && <Button className="btn-fill" color="secondary" onClick={e => updateuserstatus(2)} >Deactivate</Button>}
                                                                                    {userstatus == 2 && <Button className="btn-fill" color="secondary" onClick={e => updateuserstatus(1)} >Activate</Button>}
                                                                                    {userstatus != 3 && <Button className="btn-fill" color="secondary" onClick={e => updateuserstatus(3)} >Delete</Button>}
                                                                                </>}
                                                                                {
                                                                                    selfselected && <> <Label md="2">Conatct Admin for your information update/modify</Label></>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Form>
                                                                </CardFooter>
                                                            </Card>
                                                        </Col>
                                                        <Col md="4">
                                                            <Row>
                                                                <Col md="12">
                                                                    <div className="fileinput " style={{ border: '2px solid black', borderRadius: '5px', height: "350px", width: "380px", }}>
                                                                        <img alt="Select User" style={{
                                                                            height: "100%", width: "100%", borderColor: "red", resizeMode: "contain", borderWidth: 5, tintColor: "#000000"
                                                                        }} src={editselectedImage} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="6">
                                                                    <Button onClick={handleClick}>
                                                                        Upload a file
                                                                    </Button>
                                                                    <input type="file"
                                                                        ref={hiddenFileInput}
                                                                        onChange={handleChangeedit}
                                                                        style={{ display: 'none' }}
                                                                    /></Col>
                                                                <Col md="6">
                                                                    {editselectedImage && <Button color="primary" type="submit" onClick={() => editsetSelectedImage(null)}>Remove</Button>}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </TabPanel>
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </TabPanel>
                                <TabPanel>
                                    <Row>
                                        <Col md="8">
                                            <Card>
                                                <CardBody>
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="2">Prefix</Label>
                                                            <Col md="4" >
                                                                <FormGroup>
                                                                    <Select
                                                                        options={prefixOptions} onChange={prefixhandle} defaultValue={prefix} value={prefix}
                                                                        placeholder="Prefix"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Label md="2">Role</Label>
                                                            <Col md="4" >
                                                                <FormGroup>
                                                                    <Select
                                                                        options={roleOptions} onChange={rolehandle} defaultValue={role} value={role}
                                                                        placeholder="Role"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="2">First Name</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="text" name='first_name' onChange={firstnamehandle} value={first_name} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>

                                                            <Label md="2">Last Name</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="text" onChange={lastnamehandle} value={last_name} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="2">Email</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="email" onChange={mailnamehandle} value={mail} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Label md="2">DOB</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="date" onChange={dobhandle} value={dob} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="2">NPI Number</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="text" onChange={npihandle} value={npi} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Label md="2">Cell Phone Number</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="number" onChange={mobilenumberhandle} value={mobilenumber} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="2">Select Location</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Select options={locations} isMulti onChange={locationchangehandle} defaultValue={selectedlocation} value={selectedlocation} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Label md="2">Address</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="textarea" onChange={addresshandle} value={address} multiline autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Col md="6">
                                                            </Col>
                                                            <Col md="6">
                                                                <Button
                                                                    className="btn-fill"
                                                                    color="primary"
                                                                    onClick={submituser}
                                                                >submit</Button>
                                                                <Button
                                                                    className="btn-fill"
                                                                    color="secondary"
                                                                    type="reset" onClick={reset}
                                                                >Reset</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                        <Col md="4">
                                            <Row>
                                                <Col md="12">
                                                    <div className="fileinput " style={
                                                        {
                                                            border: '2px solid black', borderRadius: '5px', height: "350px", width: "380px",
                                                        }
                                                    }>
                                                        <img alt="Upload Image" style={{
                                                            height: "100%", width: "100%", borderColor: "red", resizeMode: "contain",
                                                            borderWidth: 5, tintColor: "#000000"
                                                        }} src={selectedImage && (URL.createObjectURL(selectedImage))} />

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <Button onClick={handleClick}>
                                                        Upload a file
                                                    </Button>
                                                    <input type="file"
                                                        ref={hiddenFileInput}
                                                        onChange={handleChange2}
                                                        style={{ display: 'none' }}
                                                    /></Col>
                                                <Col md="6">
                                                    {selectedImage && <Button color="primary" type="submit" onClick={() => setSelectedImage(null)}>Remove</Button>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPanel>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </div >
    )

}

export default Users;