import TextField from '@mui/material/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    Button, Card, CardBody, CardImg,
    CardText, CardTitle, Col, CustomInput, FormGroup, Input, Label, Row, Table
} from "reactstrap";
import { authenticationService } from '../../Services/authenticationService';
import DeletedChargestable from './deletedChargestable';
import { DefaultColumnFilter, Filter } from './Filter';
import ProcessedChargesTable from './ProcessedChargesTable';
import ReturnedtophysicianTable from './ReturnedtophysicianTable';
import Dataentrytable from './Daraentrytable';



const Charges = (props) => {
    const history = useHistory();

    if (authenticationService.currentUserValue == null) {
        history.push('/auth/login');
    }
    const [showimage, setshowimage] = React.useState(true);
    const [data, setData] = useState([]);
    const [allcharges, setallcharges] = useState([]);
    const [deletedcharge, setdeletedcharges] = useState([]);
    const [charge, setactivecharge] = useState([]);
    const [singleSelect, setsingleSelect] = useState(null);
    const columns = useMemo(
        () => [
            {
                Header: 'Charge ID',
                accessor: 'charge_id',
            },
            {
                Header: 'Charge Date',
                accessor: 'CREATED_AT',
            },
            {
                Header: 'Location',
                accessor: 'LOCATION_ID',
            },
            {
                Header: 'Physician Name',
                accessor: 'physician',
            },
            {
                Header: 'MRN#',
                accessor: 'MRNumber',
            },
            {
                Header: 'First Name',
                accessor: 'FIRST_NAME',
            },
            {
                Header: 'Last Name',
                accessor: 'LAST_NAME',
            },
            {
                Header: 'Hospital Acc',
                accessor: 'HOSPITAL_ACCOUNT_NUMBER',
            },
            {
                Header: 'Group ID',
                accessor: 'GROUP_ID',
            },
            {
                Header: 'Critical Care',
                accessor: d => { return d.CRITICAL_CARE ? 'yes' : 'no' },
            },
            {
                Header: 'Critical Care Duration',
                accessor: 'CRITICAL_CARE_DURATION',
            },
            {
                Header: 'IP/OBS',
                accessor: 'OPSOROBSER',
            },
            {
                Header: 'Encounter Type',
                accessor: 'ENCOUNTER_TYPE',
            },
            {
                Header: 'Document Type',
                accessor: 'TYPEOFDOCUMENT',
            },
            {
                Header: 'Notes',
                accessor: 'PHYSICIAN_NOTES',
            },
        ],
        []
    );
    const {
        getTableProps, getTableBodyProps, headerGroups, page, prepareRow, visibleColumns, canPreviousPage, canNextPage, pageOptions, pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const statusOptions = [
        { value: 1, label: "New" },
        { value: 5, label: "Data Entry Completed" },
        { value: 2, label: "Processed" },
        { value: 3, label: "Returned to Physician" },
        { value: 4, label: "Deleted" }
    ];
    useEffect(() => {
        doFetch();
    }, []);

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    const [ActivechargeID, setActivechargeID] = useState("");
    const [activechargestatus, setactivechargestatus] = useState(1);
    const doSomething = async (val) => {
        setactivecharge("");
        setActivechargeID("");
        setInsurenceValues([{ id: "", servicedate: "", cpt: "", modifier: "", icd10: "" }])
        if (val != "") {
            allcharges.map((charges) => {
                if (charges.charge_id == val.value) {
                    setactivecharge(charges);
                    setState(charges.STATUS);
                    setActivechargeID(val.value);
                    setactivechargestatus(chargeoptionselected(charges.STATUS));
                    setmrnnumber(charges.MRNumber);
                    if (charges.DOB == "" || charges.DOB == undefined) {
                        setdob('');
                    } else {
                        setdob(charges.DOB);
                    }
                    setfirstname(charges.FIRST_NAME);
                    setlastname(charges.LAST_NAME);
                    sethospitalaccount(charges.HOSPITAL_ACCOUNT_NUMBER);
                    setfinacialclass(charges.financial_class);
                    setinsurenceaccount(charges.INSURENCE_ACCOUUNT_NUMBER);
                    setgroupid(charges.GROUP_ID);

                    if (charges.insurencedata != null) {
                        var insurenceinfo = [];
                        charges.insurencedata.insurence.forEach(element => {
                            if (element.status == 1) {
                                insurenceinfo.push({ id: element._id, servicedate: element.serviceDate, cpt: element.cpt_code, modifier: element.modifier, icd10: element.icd_10 });
                            }
                        });
                        setInsurenceValues(insurenceinfo);
                    }
                }
            });
        }
    }

    const [alllocations, setalllocations] = useState([]);
    const getallocations = async () => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getlocations/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(json => {
                    setalllocations(json.data);
                    doFetch();
                });
            });
            if (!response.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
            const body = await response.json();
            setalllocations(body);
        } catch (err) {

        }
    }
    const handleActiveMode = () => {
        if (ActivechargeID != "") {
            setshowimage(!showimage);
        } else {
            setshowimage(!showimage);
            alert("Select Charge");
        }
    };

    const [processedcharges, setprocessedcharges] = useState([]);
    const [returnedtophysiciancharges, setreturnedtophysiciancharges] = useState([]);
    const [dataentrycompletedcharges, setdataentrycompletedcharges] = useState([]);

    function dateFormat(CREATED_AT) {
        const date = new Date(CREATED_AT);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minites = date.getMinutes();
        const monthString = month >= 10 ? month : `0${month}`;
        const dayString = day >= 10 ? day : `0${day}`;
        const hourString = hours >= 10 ? hours : `0${hours}`;
        const minitesString = minites >= 10 ? minites : `0${minites}`;
        return `${monthString}/${dayString}/${date.getFullYear()} ${hourString}:${minitesString}`;
    }

    const [allusers, setallusers] = useState([]);
    const getusers = async () => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/users/getusers/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(json => { setallusers(json.data); });
                getallocations();
            }
            );
            if (!response.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
        } catch (err) {

        }
    }

    const doFetch = async () => {
        try {
            let token = localStorage.getItem('token');

            const locations = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getlocations/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const locations_result = await locations.json();
            const alllocations = locations_result.data.locations;

            if (!locations_result.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
            const allusers = await fetch(process.env.REACT_APP_API_HOST + '/users/getusers/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const users_data = await allusers.json();

            const all = users_data.data;
            if (!users_data.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
            const user_id = authenticationService.useridvalue;

            const response = await fetch(process.env.REACT_APP_API_HOST + '/charges/getchargesbyuserid/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    skip: 0, limit: 0, CREATED_BY: user_id
                })
            });

            const body = await response.json();

            const Charges = body.data.charges;
            for (const val of Charges) {
                val.CREATED_AT = dateFormat(val.CREATED_AT);
                var Locations = Enumerable.from(alllocations)
                    .where(w => w.location_id == val.LOCATION_ID)
                    .toArray();
                if (Locations.length > 0) {
                    val.LOCATION_ID = Locations[0].Name;
                }
                else {
                    val.LOCATION_ID = "-";
                }

                var users = Enumerable.from(all).where(w => w.user_id == val.CREATED_BY).toArray();

                if (users.length > 0) {
                    val.physician = users[0].first_name + " " + users[0].last_name;
                } else {
                    val.physician = "-";
                }
            };
            setallcharges(Charges);
            var result = Enumerable.from(Charges)
                .where(w => w.STATUS == 1)
                .toArray();

            setData(result);
            var resultPreocessed = Enumerable.from(Charges)
                .where(w => w.STATUS == 2)
                .toArray();

            setprocessedcharges(resultPreocessed);

            var resultreturned = Enumerable.from(Charges)
                .where(w => w.STATUS == 3)
                .toArray();
            setreturnedtophysiciancharges(resultreturned);
            var resultdeleted = Enumerable.from(Charges)
                .where(w => w.STATUS == 4)
                .toArray();
            setdeletedcharges(resultdeleted);
            var resultdeleted = Enumerable.from(Charges)
                .where(w => w.STATUS == 5)
                .toArray();
            setdataentrycompletedcharges(resultdeleted);


        } catch (err) {
           
        }
    };

    const [hospitalaccount, sethospitalaccount] = useState("");
    const [finacialclass, setfinacialclass] = useState("");
    const [insurenceaccount, setinsurenceaccount] = useState("");
    const [groupid, setgroupid] = useState("");
    const [mrnnumber, setmrnnumber] = useState("");
    const [dob, setdob] = useState();
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");

    function getdurationformat(Duration) {
        const date = new Date(Duration);
        const hours = date.getHours();
        const minites = date.getMinutes();
        const hourString = hours >= 10 ? hours : `0${hours}`;
        const minitesString = minites >= 10 ? minites : `0${minites}`;
        return ` ${hourString}:${minitesString}`;
    }

    $(document).ready(function () {
        $('#myTable').on('click', '.clickable-row', function (event) {
            $(this).addClass('row-selected').siblings().removeClass('row-selected');
        });
    })


    $(document).on('contextmenu', 'img', function () {
        return false;
    });

    const [inputList, setInputList] = useState([{ servicedate: "", cpt: "", modifier: "", icd10: "" }]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...InsurenceValues];
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = index => {
        const list = [...InsurenceValues];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { servicedate: "", cpt: "", modifier: "", icd10: "" }]);
    };

    const addchargedetails = async () => {
        try {
            if (ActivechargeID == "" || ActivechargeID == null) {
                alert("Select Charge..");
                return;
            }
            if (mrnnumber == "" | mrnnumber == null) {
                alert("Enter MRN Number");
                return;
            }
            const user_id = authenticationService.useridvalue;

            const postbody = JSON.stringify({
                charge_id: ActivechargeID,
                MRN: mrnnumber,
                FIRST_NAME: firstname,
                LAST_NAME: lastname,
                DOB: dob,
                ACCOUNT_NUMBER: "",
                HOSPITAL_ACCOUNT_NUMBER: hospitalaccount,
                INSURENCE_ACCOUUNT_NUMBER: insurenceaccount,
                GROUP_ID: groupid,
                financialClassPracticeGroupId: "",
                financial_class: finacialclass,
                financial_class_acc_num: "",
                STATUS: state
            });
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/charges/addpatientinfo', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: postbody
            });
            const result= await response.json();           
            if (!result.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }

            var insurencedata = [];
            for (var i = 0; i < InsurenceValues.length; i++) {
                const data = InsurenceValues[i];
                insurencedata.push(data);
            }

            const chargebody = JSON.stringify({
                charge_id: ActivechargeID,
                insurencedata: insurencedata,
                CREATED_BY: user_id,
                CREATED_AT: new Date()
            });
            const response2 = await fetch(process.env.REACT_APP_API_HOST + '/charges/addchargecodes', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: chargebody
            });

            clearpatientandchargeinfo();
            doFetch();
            doSomething("");
            alert("Charge information added..");

        } catch (err) {
           
        }
    }

    const setmrnnumberhandle = event => {
        setmrnnumber(event.target.value);
    }

    const firstnamehandle = event => {
        setfirstname(event.target.value);
    }

    const lastnamehandle = event => {
        setlastname(event.target.value);
    }

    const hospitalaccounthandle = event => {
        sethospitalaccount(event.target.value);
    }
    const insurenceaccounthandle = event => {
        setinsurenceaccount(event.target.value);
    }
    const groupidhandle = event => {
        setgroupid(event.target.value);
    }
    const finacialclasshandle = event => {
        setfinacialclass(event.target.value);
    }

    const [state, setState] = useState();
    const handleChange = (event) => {
        setState(event.value);
        if (charge != "") {
            charge.STATUS = event.value;
        }

        setactivechargestatus(chargeoptionselected(event.value));
    }

    function chargeoptionselected(value) {

        for (var i = 0; i < statusOptions.length; i++) {
            if (statusOptions[i].value == value) {
                return statusOptions[i];
            }
        }
    }

    const handleChange2 = event => {
        try {
            setdob(event.target.value);
        } catch (exception) {
            console.log(exception);
        }
    };

    function clearpatientandchargeinfo() {
        setactivecharge("");
        setActivechargeID("");
        setmrnnumber("");
        setdob('');
        setfirstname("");
        setlastname("");
        sethospitalaccount("");
        setfinacialclass("");
        setinsurenceaccount("");
        setgroupid("");
        setactivechargestatus(-1);
        setInsurenceValues([{ id: "", servicedate: "", cpt: "", modifier: "", icd10: "" }])
        setInputList([{ id: "", servicedate: "", cpt: "", modifier: "", icd10: "" }]);
        $(function () {
            $("#myTable tr.clickable-row").on("focusout", function () {
                $('t#myTable tr.clickable-row').removeClass('my-line');
                $(this).addClass('my-line');
            });
        });
        $(document).ready(function () {
            $('#myTable > tbody  > tr').each(function (index, tr) {
               
            });

            $('#myTable3 > tbody  > tr').each(function (index, tr) {
                $(this).siblings().removeClass('row-selected');               
            });

            $('#myTable4 > tbody  > tr').each(function (index, tr) {
                $(this).siblings().removeClass('row-selected');                
            });

            $('#myTable5 > tbody  > tr').each(function (index, tr) {
                $(this).siblings().removeClass('row-selected');                
            });
        });
    }

    function handleTitleChange(evt) {
        doSomething(evt);
    }

    function handleSelect() {
        clearpatientandchargeinfo();
    }

    const [InsurenceValues, setInsurenceValues] = useState([{ id: "", servicedate: "", cpt: "", modifier: "", icd10: "" }])
    let addFormFields = () => {
        setInsurenceValues([...InsurenceValues, { id: "", servicedate: "", cpt: "", modifier: "", icd10: "" }])
    }

    let removeFormFields = (i) => {
        let newInsurenceValues = [...InsurenceValues];
        newInsurenceValues.splice(i, 1);
        setInsurenceValues(newInsurenceValues)
    }
    let insurencedatachnage = (i, e) => {
        let newInsurenceValues = [...InsurenceValues];
        newInsurenceValues[i][e.target.name] = e.target.value;
        setInsurenceValues(newInsurenceValues);
    }

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Tabs onSelect={index => handleSelect()}>
                                <TabList >
                                    <Tab eventKey={1}>New</Tab>
                                    <Tab eventKey={5}>Data Entry Completed</Tab>
                                    <Tab eventKey={2}>Processed</Tab>
                                    <Tab eventKey={3}>Returned to Physician</Tab>
                                    <Tab eventKey={4}>Deleted</Tab>
                                </TabList>
                                <TabPanel>
                                    <div id="container">
                                        <Table id="myTable" styles={{ height: 120 }} height='120' bordered hover  {...getTableProps()}>
                                            <thead>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th {...column.getHeaderProps()}>
                                                                <div {...column.getSortByToggleProps()}>
                                                                    {column.render('Header')}
                                                                    {generateSortingIndicator(column)}
                                                                </div>
                                                                <Filter column={column} />
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>

                                            <tbody {...getTableBodyProps()}>
                                                {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <Fragment key={row.getRowProps().key}>
                                                            <tr key={row.getRowProps().key} class="clickable-row" onClick={((e) => doSomething(row.cells[0]))} >
                                                                {row.cells.map((cell) => {
                                                                    return (
                                                                        <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
                                        <Col md={3}>
                                            <Button
                                                color='primary'
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {'<<'}
                                            </Button>
                                            <Button
                                                color='primary'
                                                onClick={previousPage}
                                                disabled={!canPreviousPage}
                                            >
                                                {'<'}
                                            </Button>
                                        </Col>
                                        <Col md={2} style={{ marginTop: 7 }}>
                                            Page{' '}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>
                                        </Col>
                                        <Col md={2}>
                                            <Input
                                                type='number'
                                                min={1}
                                                style={{ width: 70 }}
                                                max={pageOptions.length}
                                                defaultValue={pageIndex + 1}
                                                onChange={onChangeInInput}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <CustomInput
                                                type='select'
                                                value={pageSize} style={{ color: '#000', backgroundColor: '#fff' }}
                                                onChange={onChangeInSelect}
                                            >

                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                    <option key={pageSize} value={pageSize} style={{ color: '#000', backgroundColor: '#fff' }}>
                                                        Show {pageSize}
                                                    </option>
                                                ))}
                                            </CustomInput>
                                        </Col>
                                        <Col md={3}>
                                            <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                                                {'>'}
                                            </Button>
                                            <Button
                                                color='primary'
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {'>>'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </TabPanel>
                                <TabPanel>
                                    <Dataentrytable fromChildToParentCallback={handleTitleChange} data={dataentrycompletedcharges} columns={columns}></Dataentrytable>
                                </TabPanel>
                                <TabPanel>
                                    <div id="container">
                                        <ProcessedChargesTable fromChildToParentCallback={handleTitleChange} data={processedcharges} columns={columns}></ProcessedChargesTable>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <ReturnedtophysicianTable fromChildToParentCallback={handleTitleChange} data={returnedtophysiciancharges} columns={columns}></ReturnedtophysicianTable>
                                </TabPanel>
                                <TabPanel>
                                    <DeletedChargestable fromChildToParentCallback={handleTitleChange} data={deletedcharge} columns={columns}></DeletedChargestable>
                                </TabPanel>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card>
                        <Tabs>
                            <TabList>
                                <Tab>Charge Info</Tab>
                                {/* <Tab>Patient View</Tab>
                                <Tab>Chat</Tab> */}
                            </TabList>
                            <TabPanel>
                                <Row>
                                    <Col md="8">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <Label tag="h3">Patient Info </Label>
                                                    </Col>
                                                    <Col md="6">
                                                        <Label tag="h4">  Charge ID:  {charge.charge_id}</Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="3">
                                                        <TextField label="MRN #" id="standard-size-small" value={mrnnumber} defaultValue="" onChange={setmrnnumberhandle} size="small" variant="standard" />
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="react-date-picker">
                                                            <Label>DOB </Label>{" "}
                                                            <Input type="date" onChange={handleChange2}
                                                                defaultValue={dob} value={dob}
                                                                autoComplete="off" />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <TextField label="First Name" id="standard-size-small" value={firstname} onChange={firstnamehandle} defaultValue="" size="small" variant="standard" />
                                                    </Col>
                                                    <Col md="3">
                                                        <TextField label="Last Name" id="standard-size-small" value={lastname} onChange={lastnamehandle} defaultValue="" size="small" variant="standard" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col md="3">
                                                                <TextField label="Hospital Account #" id="standard-size-small" value={hospitalaccount} onChange={hospitalaccounthandle} defaultValue="" name='icd10' size="small" variant="standard" />
                                                            </Col>
                                                            <Col md="3">
                                                                <TextField label="Financial Class" id="standard-size-small" value={finacialclass} onChange={finacialclasshandle} defaultValue="" name='icd10' size="small" variant="standard" />
                                                            </Col>
                                                            <Col md="3">
                                                                <TextField label="Insurance Account" id="standard-size-small" value={insurenceaccount} onChange={insurenceaccounthandle} defaultValue="" name='icd10' size="small" variant="standard" />
                                                            </Col>
                                                            <Col md="3">
                                                                <TextField label="Group ID" id="standard-size-small" value={groupid} onChange={groupidhandle} defaultValue="" name='icd10' size="small" variant="standard" />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr></hr>
                                                <Row>
                                                    <Col md="12">
                                                        {InsurenceValues.map((element, index) => (
                                                            <div className="form-inline" key={index}>
                                                                <Row>

                                                                    <Col md="3">
                                                                        <TextField type="date" name="servicedate" id="outlined-basic" variant="standard" value={element.servicedate || ""} onChange={e => insurencedatachnage(index, e)} />
                                                                    </Col>
                                                                    <Col md="2">
                                                                        <TextField type="text" name="cpt" id="outlined-basic" label="CPT" variant="standard" value={element.cpt || ""} onChange={e => insurencedatachnage(index, e)} />
                                                                    </Col>
                                                                    <Col md="2">
                                                                        <TextField type="text" name="modifier" id="outlined-basic" label="Modifier" variant="standard" value={element.modifier || ""} onChange={e => insurencedatachnage(index, e)} />
                                                                    </Col>
                                                                    <Col md="3">
                                                                        <TextField multiline name="icd10" id="outlined-basic" label="ICD-10" variant="standard" value={element.icd10 || ""} onChange={e => insurencedatachnage(index, e)} />
                                                                    </Col>
                                                                    {
                                                                        index ?
                                                                            <Col>
                                                                                <Button type="button" className="button remove" onClick={() => removeFormFields(index)}>-</Button>
                                                                            </Col>
                                                                            : null
                                                                    }

                                                                </Row>
                                                            </div>
                                                        ))}
                                                        <div className="button-section">
                                                            <Button className="button add" type="button" onClick={() => addFormFields()}>+</Button>

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Label md="2" style={{ fontSize: 16 }}> Charge Status</Label>
                                                    <Col md="4" >
                                                        <FormGroup>
                                                            <Select options={statusOptions}
                                                                onChange={handleChange} defaultValue={activechargestatus}
                                                                placeholder="Status" value={activechargestatus}
                                                            />

                                                        </FormGroup>

                                                    </Col>
                                                    <Col md="3">
                                                        <Button className="btn-fill" onClick={() => addchargedetails()}>Submit</Button>
                                                    </Col>
                                                </Row>
                                            </CardBody>

                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Row>
                                            <Col md="10">
                                                <div className="d-flex align-items-center">
                                                    <span className="mr-2">Image</span>
                                                    <CustomInput styles={{ Color: 'red', backgroundColor: 'yellow' }}
                                                        type="switch"
                                                        id="switch-5"
                                                        className="mt-n4"
                                                        onChange={handleActiveMode}
                                                        value={showimage} />
                                                    <span className="ml-n2">Charge Data</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md="10">
                                                <Card>
                                                    <CardBody>
                                                        {showimage && <div style={{ borderStyle: "solid" }}> <CardImg alt="Select charge.." src={`${charge.CHARGE_IMAGE}`} /></div>}
                                                        {!showimage && <>
                                                            {charge != "" && <> <Row>
                                                                <Col md="6">
                                                                    <CardTitle>Critical Care</CardTitle>
                                                                </Col>
                                                                <Col md="6">
                                                                    <CardText>{ActivechargeID != "" ? (charge.CRITICAL_CARE ? "YES" : "NO") : ""}</CardText>
                                                                </Col>
                                                            </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <CardTitle>Duration</CardTitle>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <CardText>{charge.CRITICAL_CARE_DURATION}</CardText>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <CardTitle>I/P or OBS</CardTitle>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <CardText>{ActivechargeID != "" ? (charge.OPSOROBSER != null ? charge.OPSOROBSER.toUpperCase() : "") : ""}</CardText>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <CardTitle>EnCounter Type</CardTitle>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <CardText>{ActivechargeID != "" ? (charge.ENCOUNTER_TYPE != undefined ? (charge.ENCOUNTER_TYPE.toUpperCase()) : "") : ""}</CardText>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <CardTitle>Type of Documentation</CardTitle>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <CardText>{ActivechargeID != "" ? (charge.TYPEOFDOCUMENT.toUpperCase()) : ""}</CardText>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <CardTitle>Physician Notes</CardTitle>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <textarea value={ActivechargeID != "" ? (charge.PHYSICIAN_NOTES.toUpperCase()) : ""} disabled="disaled"></textarea>
                                                                    </Col>
                                                                </Row></>}
                                                        </>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPanel>
                            {/* <TabPanel>
                                <h2>Patient Info</h2>
                            </TabPanel>
                            <TabPanel>
                                <h2>Chat</h2>
                            </TabPanel> */}

                        </Tabs>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default Charges;