import $ from 'jquery';
import React, { Fragment } from 'react';
import {
    useExpanded, useFilters, usePagination, useSortBy, useTable
} from 'react-table';
import { Button, Col, CustomInput, Input, Row, Table } from 'reactstrap';
import { DefaultColumnFilter, Filter } from './Filter';
import Enumerable from 'linq';
const Dataentrytable = ({ columns, data, fromChildToParentCallback }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    const doSomething = (val) => {

        fromChildToParentCallback(val);
    }

    const getval = () => {
        return 0;
    }

    $(document).ready(function () {
        $('#myTable5').on('click', '.clickable-row', function (event) {
            $(this).addClass('row-selected').siblings().removeClass('row-selected');
        });
    })

    return (
        <Fragment>
            <div id="container">
                <Table id="myTable5" styles={{ height: 120 }} height='120' bordered hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        <div {...column.getSortByToggleProps()}>
                                            {column.render('Header')}
                                            {generateSortingIndicator(column)}
                                        </div>
                                        <Filter column={column} />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr class="clickable-row" onClick={((e) => doSomething(row.cells[0]))} >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                    {/* {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )} */}
                                </Fragment>
                            );
                        })}
                    </tbody>

                </Table>
            </div>

            <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
                <Col md={3}>
                    <Button
                        color='primary'
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </Button>
                    <Button
                        color='primary'
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </Button>
                </Col>
                <Col md={2} style={{ marginTop: 7 }}>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Col>
                <Col md={2}>
                    <Input
                        type='number'
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                    />
                </Col>
                <Col md={2}>
                    <CustomInput
                        type='select'
                        value={pageSize} style={{ color: '#000', backgroundColor: '#fff' }}
                        onChange={onChangeInSelect}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </CustomInput>
                </Col>
                <Col md={3}>
                    <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                        {'>'}
                    </Button>
                    <Button
                        color='primary'
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Dataentrytable;