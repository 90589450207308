
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row
} from "reactstrap";
import { async } from 'rxjs';
import { authenticationService } from '../../Services/authenticationService';
import Practicegroupstable from './practicegroupstable';

const Practicegroup = (props) => {
    const history = useHistory();
    if (authenticationService.currentUserValue == null) {
        history.push('/auth/login');
    }
    const [user_data, setuser_data] = useState();
    const [data, setData] = useState([]);
    useEffect(async () => {
        await getallmappings();
        setuser_data(authenticationService.userdataValue);

    }, []);

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };
    const columns = useMemo(
        () => [
            {
                Header: 'Practice Group ID',
                accessor: 'group_id',
            },
            {
                Header: 'Practice Group Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
            }
        ]

    );

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 150,
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 220,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
    }));

    const classes = useStyles();
    const [selectedImage, setSelectedImage] = React.useState(null);

    const [Groupname, setGroupname] = useState("");
    const Groupnamehandle = event => {
        setGroupname(event.target.value);
    }
    const [description, setdescription] = useState("");
    const descriptionhandle = event => {
        setdescription(event.target.value);
    }

    const reset = () => {
        setSelectedImage("");
        setGroupname("");
        setdescription("");
    }
    const [allmappings, setallmapings] = useState([]);
    const getallmappings = async () => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/users/getgroupsmapping/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => {
                response.json().then(json => {                
                    if (!json.success) {
                        authenticationService.logout(true);
                        history.push('/auth/login?session=false');
                        return;
                    }
                    setallmapings(json.data);
                    getallgroups(json.data);
                })
            });
        } catch (err) {
            
        }
    }

    const addpracticegroup = () => {
        try {
            let token = localStorage.getItem('token');
            const user_id = authenticationService.useridvalue;
            if (Groupname.trim() == "") {
                alert("Enter Practice Group Name");
                return;
            }

            const formData = new FormData();
            formData.append('file', selectedImage);
            formData.append('groupname', Groupname);
            formData.append('description', description);
            formData.append('created_by', user_id);
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            };
            axios.post(process.env.REACT_APP_API_HOST + '/practicegroup/newgroup', formData, headers, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round(
                        ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                }
            }).then(res => {
                alert(res.data.message);
            }).catch(err => console.log())
        } catch (err) {
            alert(err);
        }
        setGroupname("");
        setdescription("");
        setSelectedImage("");
        getallgroups();
    }
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange2 = event => {
        const fileUploaded = event.target.files[0];
        setSelectedImage(fileUploaded);
    };

    const getallgroups = async (allmappings) => {
        try {
            let token = localStorage.getItem('token');
            const user_id = authenticationService.useridvalue;
            const role = authenticationService.userroleValue;

            const response = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getgroups/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            var array = [];
            const body = await response.json();
            const groups = body.data;
            for (var i = 0; i < groups.length; i++) {
                if (groups[i].CREATED_BY == user_id) {
                    array.push(groups[i]);
                }
            };

            if (role == 1) {
                for (var i = 0; i < allmappings.length; i++) {
                    if (user_id == allmappings[i].user_id) {
                        var allgroups = allmappings[i].group_mapping;
                        for (var groupmap of allgroups) {
                            for (var mapping of groups) {
                                if (mapping.group_id == groupmap.group_id) {
                                    for (var arr of array) {
                                        if (arr.group_id != mapping.group_id) {
                                            array.push(mapping);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            let array2 = [...new Set(array)];
            setData(array2);
        } catch (err) {
            console.log();
        }
    };

    function handleSelect() {
        getallgroups();
    }

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Tabs onSelect={index => handleSelect()} >
                                <TabList >
                                    <Tab eventKey={1}>Practice Groups</Tab>
                                    <Tab eventKey={2}>New Group</Tab>
                                </TabList>
                                <TabPanel>
                                    <div id="container">
                                        <Practicegroupstable columns={columns} data={data}></Practicegroupstable>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <Row>
                                        <Col md="8">
                                            <Card>
                                                <CardBody>
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="2">Group Name</Label>
                                                            <Col md="10">
                                                                <FormGroup>
                                                                    <Input type="text" value={Groupname} onChange={Groupnamehandle} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Label md="2">Description</Label>
                                                            <Col md="10">
                                                                <FormGroup>
                                                                    <Input type="textarea" value={description} onChange={descriptionhandle} multiline autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Col md="6">
                                                            </Col>
                                                            <Col md="6">
                                                                <Button
                                                                    className="btn-fill"
                                                                    color="primary"
                                                                    onClick={addpracticegroup}
                                                                >
                                                                    submit
                                                                </Button>

                                                                <Button
                                                                    className="btn-fill"
                                                                    color="secondary" onClick={reset}
                                                                    type="reset"
                                                                >
                                                                    Reset
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                        <Col md="4">
                                            <Row>
                                                <Col md="12">
                                                    <div className="fileinput " style={{ border: '2px solid black', borderRadius: '5px', height: "350px", width: "380px" }}>
                                                        <img alt="Upload Image" style={{
                                                            height: "100%", width: "100%", borderColor: "red", resizeMode: "contain",
                                                            borderWidth: 5, tintColor: "#000000"
                                                        }} src={selectedImage && (URL.createObjectURL(selectedImage))} />

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <Button onClick={handleClick}>
                                                        Upload a file
                                                    </Button>
                                                    <input type="file"
                                                        ref={hiddenFileInput}
                                                        onChange={handleChange2}
                                                        style={{ display: 'none' }}
                                                    /></Col>
                                                <Col md="6">
                                                    {selectedImage && <Button color="primary" type="submit" onClick={() => setSelectedImage(null)}>Remove</Button>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPanel>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div >
    )

}

export default Practicegroup;