import Charges from "./views/Charges/Charges";
import Locations from "./views/locationmanagement/locations";
import Practicegroup from "./views/practicegroup/practicegroup";
import users from "./views/usermanagment/users";
const routes = [
   
    {
        path: "/PracticeGroup",
        name: "Practice Group Management",
        icon: "fas fa-medkit",
        component: Practicegroup,
        layout: "/admin",       
    },
    {
        path: "/Locations",
        name: "Locations Management",
        icon: "fas fa-medkit",
        component: Locations,
        layout: "/admin",
       
    },
    {
        path: "/Users",
        name: "User management",
        icon: "fas fa-medkit",
        component: users,
        layout: "/admin",
       
    } ,
    {
        path: "/charges",
        name: "Charges",
        icon: "fas fa-medkit",
        component: Charges,
        layout: "/user",       
    }

];

export default routes;
