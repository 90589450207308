import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row
} from "reactstrap";
import { async } from 'rxjs';
import { authenticationService } from '../../Services/authenticationService';
import Locationstable from './Locationstable';

const Locations = (props) => {
    const history = useHistory();

    if (authenticationService.currentUserValue == null) {
        history.push('/auth/login');
    }
    const [data, setData] = useState([]);
    useEffect(() => {

        getallmappings();

    }, []);

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };
    const columns = useMemo(
        () => [
            {
                Header: 'Location ID',
                accessor: 'location_id',
            },
            {
                Header: 'Location Name',
                accessor: 'Name',
            },
            {
                Header: 'Mobile Number',
                accessor: 'Mobilenumber',
            },
            {
                Header: 'Address',
                accessor: 'Address',
            },
            {
                Header: 'Description',
                accessor: 'Description',
            }

        ]

    );

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 150,
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 220,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
    }));

    const classes = useStyles();
    const [selectedImage, setSelectedImage] = React.useState(null);

    const [description, setdescription] = useState("");
    const descriptionhandle = event => {
        setdescription(event.target.value);
    }
    const [allmappings, setallmapings] = useState([]);
    const getallmappings = async () => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/users/getgroupsmapping/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => {
                response.json().then(json => {
                    if (!json.success) {
                        authenticationService.logout(true);
                        history.push('/auth/login?session=false');
                        return;
                    }
                    setallmapings(json.data.docs);
                    getallgroups(json.data.docs);
                    getallocations();
                })
            });
        } catch (err) {
           
        }
    }
    const [groups, setgroups] = useState([]);
    const getallgroups = async (allmappings) => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getgroups/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            const body = await response.json();
            const groups = body.data;
            if (!body.success) {
                authenticationService.logout(true);
                history.push('/auth/login?session=false');
                return;
            }
            var array = [];
            const user_id = authenticationService.useridvalue;
            const role = authenticationService.userroleValue;
            for (var i = 0; i < groups.length; i++) {
                if (groups[i].CREATED_BY == user_id) {
                    array.push(groups[i]);
                }
            };

            if (role == 1) {
                for (var i = 0; i < allmappings.length; i++) {
                    if (user_id == allmappings[i].user_id) {
                        var allgroups = allmappings[i].group_mapping;
                        for (var groupmap of allgroups) {
                            for (var mapping of groups) {
                                if (mapping.group_id == groupmap.group_id) {
                                    array.push(mapping);
                                }
                            }
                        }
                    }
                }
            }

            let array2 = [...new Set(array)];
            const options = array2.map(d => ({
                "value": d.group_id,
                "label": d.name
            }))
            setgroups(options);
            if (selectedgroup != null) {
                group_chnage(selectedgroup);
                getallocations(options[0].value);
            } else
                if (options.length > 0) {
                    setSelectedgroup(options[0]);
                    setactivegroup(options[0].value);
                    group_chnage(options[0].value);
                    getallocations(options[0].value);
                }
        } catch (err) {
           
        }
    };

    const [locations, setlocations] = useState([]);
    const [alllocations, setalllocations] = useState([]);
    const getallocations = async (group_id) => {
        try {
            let token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_API_HOST + '/practicegroup/getlocations/', {
                method: 'Post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(json => {
                    if (!json.success) {
                        authenticationService.logout(true);
                        history.push('/auth/login?session=false');
                        return;
                    }
                    setalllocations(json.data.locations);
                    var array2 = {
                        user: []
                    };
                    const locations = json.data;

                    for (var i = 0; i < locations.locations.length; i++) {
                        var val = locations.locations[i];
                        if (val.group_id == group_id) {
                            array2.user.push(val);
                        }
                    }

                    setData(array2.user);

                });
            });
        } catch (err) {
           
        }
    };

    const [selectedgroup, setSelectedgroup] = useState();
    const [activegroup, setactivegroup] = useState();
    const groupchangehandle = event => {
        group_chnage(event.value);
    }

    function group_chnage(group_id) {
        groups.forEach(element => {
            if (element.value == group_id) {
                setSelectedgroup(element);
            }
        });

        setactivegroup(group_id);
        var array2 = {
            user: []
        };

        for (var val of alllocations) {
            if (val.group_id == group_id) {
                array2.user.push(val);
            }
        }
        setData(array2.user);
    }

    const [locationname, setlocationname] = useState("");
    const locationnamehandle = event => {
        setlocationname(event.target.value);
    }
    const [contactnumber, setcontactnumber] = useState("");
    const contactnumberhandle = event => {
        setcontactnumber(event.target.value);
    }
    const [address, setaddress] = useState("");
    const addresshandle = event => {
        setaddress(event.target.value);
    }

    const addlocation = async () => {
        try {
            const user_id = authenticationService.useridvalue;
            if (activegroup == null) {
                alert("Select Practice Group");
                return;
            }
            if (locationname.trim() == "") {
                alert("Enter Location Name");
                return;
            }
            if (contactnumber && contactnumber.trim() == "") {
                alert("Enter Contact Number");
                return;
            }
            if (address && address.trim() == "") {
                alert("Enter Address");
                return;
            }

            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('file', selectedImage);
            formData.append('group_id', activegroup);
            formData.append('locationname', locationname);
            formData.append('contactnumber', contactnumber);
            formData.append('address', address);
            formData.append('description', description);
            formData.append("user_id", user_id);

            axios.post(process.env.REACT_APP_API_HOST + '/practicegroup/newlocation', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                if (!res.data.success) {
                    authenticationService.logout(true);
                    history.push('/auth/login?session=false');
                    return;
                }
                alert(res.data.message);

            }).catch(err => console.log())
        } catch (err) {
            alert(err);
        }
        setlocationname("");
        setdescription("");
        setSelectedImage("");
        setcontactnumber("");
        setaddress("");
        getallgroups();
    }
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange2 = event => {
        const fileUploaded = event.target.files[0];
        setSelectedImage(fileUploaded);
    };

    function reset() {
        setlocationname("");
        setdescription("");
        setSelectedImage("");
        setcontactnumber("");
        setcontactnumber("");
        setaddress("");
    }

    function handleSelect() {
        getallocations();
    }

    return (
        <div className="content">
            <Row>
                <Label md="2">Select Practice Group</Label>
                <Col md="6">
                    <FormGroup>
                        <Select options={groups} onChange={groupchangehandle} defaultValue={selectedgroup} value={selectedgroup} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Tabs onSelect={index => handleSelect()}>
                                <TabList >
                                    <Tab eventKey={1}>Locations</Tab>
                                    <Tab eventKey={2}>New Location</Tab>
                                </TabList>
                                <TabPanel>
                                    <Locationstable columns={columns} data={data}></Locationstable>
                                </TabPanel>
                                <TabPanel>
                                    <Row>
                                        <Col md="8">
                                            <Card>
                                                <CardBody>
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="2">Location Name</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="text" value={locationname} onChange={locationnamehandle} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Label md="2">Description</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="text" value={description} onChange={descriptionhandle} multiline autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="2">Contact Mobile Number</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="number" value={contactnumber} onChange={contactnumberhandle} autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>

                                                            <Label md="2">Address</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input type="textarea" value={address} onChange={addresshandle} multiline autoComplete="off" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Col md="6">
                                                            </Col>
                                                            <Col md="6">
                                                                <Button className="btn-fill" color="primary" onClick={addlocation}>submit</Button>
                                                                <Button className="btn-fill" color="secondary" onClick={reset} type="reset">Reset</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                        <Col md="4">
                                            <Row>
                                                <Col md="12">
                                                    <div className="fileinput " style={{ border: '2px solid black', borderRadius: '5px', height: "350px", width: "380px" }}>
                                                        <img alt="Upload Image" style={{
                                                            height: "100%", width: "100%", borderColor: "red", resizeMode: "contain",
                                                            borderWidth: 5, tintColor: "#000000"
                                                        }} src={selectedImage && (URL.createObjectURL(selectedImage))} />

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <Button onClick={handleClick}>
                                                        Upload a file
                                                    </Button>
                                                    <input type="file"
                                                        ref={hiddenFileInput}
                                                        onChange={handleChange2}
                                                        style={{ display: 'none' }}
                                                    /></Col>
                                                <Col md="6">
                                                    {selectedImage && <Button color="primary" type="submit" onClick={() => setSelectedImage(null)}>Remove</Button>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPanel>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div >
    )

}

export default Locations;