import { BehaviorSubject } from 'rxjs';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const currentUseridSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userid')));
const currentUserRoleidSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('role')));
const currentUserDataSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('default')));
const tokenDataSubject = new BehaviorSubject(localStorage.getItem('token'));
export const authenticationService = {
    login,
    logout,
    userdata: currentUserDataSubject.asObservable(),
    currentUser: currentUserSubject.asObservable(),
    user_id: currentUseridSubject.asObservable(),
    userrole: currentUserRoleidSubject.asObservable(),
    token: tokenDataSubject.asObservable(),
    get useridvalue() { return currentUseridSubject.value },
    get currentUserValue() { return currentUserSubject.value },
    get userdataValue() { return currentUserDataSubject.value },
    get userroleValue() { return currentUserRoleidSubject.value },
    get tokenvalue() { return tokenDataSubject.value }
};

async function login(username, password) {
    try {
        const response = await fetch(process.env.REACT_APP_API_HOST + '/users/login/', {
            method: 'Post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mail_id: username, password: password
            })
        });

        const status = await response.json();       
        if (status.result === "success" || status.result === "default password") {
            localStorage.setItem('currentUser', JSON.stringify(username));
            currentUserSubject.next(username);
            localStorage.setItem('default', JSON.stringify(status.data));
            currentUserDataSubject.next(status.data);
            currentUseridSubject.next(status.data.docs.user_id);
            localStorage.setItem('userid', status.data.docs.user_id);
            currentUserRoleidSubject.next(status.data.docs.role);
            localStorage.setItem('role', status.data.docs.role);
            tokenDataSubject.next(status.token);
            localStorage.setItem('token', status.token);
            return true;
        }
        else {
            return false;
        }
    } catch (err) {
       
        return false;
    }
}

function logout(showalert) {    
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    localStorage.removeItem('default');
    currentUserDataSubject.next(null);    
    localStorage.removeItem('userid');
    currentUseridSubject.next(null);    
    localStorage.removeItem('role');
    currentUserRoleidSubject.next(null);   
    localStorage.removeItem('token');
    tokenDataSubject.next(null);
}